<template>
  <div>
    <vs-popup class="sm:popup-w-50" title="Signature Pad" :z-index="100001" :active="isActive" v-on:update:active="emitModalIsActive">
      <div v-if="isActive" class="border border-solid border-primary bg-white">
        <VueSignaturePad width="100%" height="70vh" ref="signaturePad" :options="padOptions"/>
      </div>
      <div class="vx-row float-right mt-6">
        <div class="vx-col w-full">
          <vs-button class="mr-3 px-3" type="border" @click="clear">Clear</vs-button>
          <vs-button class="mr-3 px-3" type="border" @click="undo">Undo</vs-button>
          <vs-button class="px-3" @click="save">Simpan</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SignaturePad',
  props: ['isActive'],
  data () {
    return {
      signature: {
        blob: null,
        dataUrl: null
      },
      padOptions: {
        minWidth: 1.5
      }
    }
  },
  methods: {
    save () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (isEmpty) {
        this.notifyWarning('Tanda tangan masih kosong')
      } else {
        this.signature = { blob: this.dataUrlToBlob(_.cloneDeep(data)), dataUrl: _.cloneDeep(data) }
        this.emitSaved()
      }
    },

    undo () {
      this.$refs.signaturePad.undoSignature()
    },

    clear () {
      this.$refs.signaturePad.clearSignature()
      this.resetSignature()
    },

    resetSignature () {
      this.signature = null
    },

    dataUrlToBlob (dataUrl) {
      const arr = dataUrl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },

    emitSaved () {
      this.$emit('saved', this.signature)
      this.emitModalIsActive(false)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
      this.resetSignature()
    }
  }
}
</script>
